import {initFoundation} from './foundation-init';
import initSwagger from './swagger';
import initStickybits from './stickybits-init';

import '../scss/base.scss';

initFoundation();

window.mbed = window.mbed || {};
window.mbed.initSwagger = initSwagger;

initStickybits();
